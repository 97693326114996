import React, { useState } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { SIGNIN_MUTATION } from "@scanow/services";
import userAuth from "@/helpers/auth";
import { toast } from "react-toastify";
import {
  Loading,
  InputField,
  SubmitButton,
  Link,
  WYSIWYGField,
} from "@scanow/ui";


interface IFormSigin {
  email: string;
  password: string;
}

export default function FormSignin(): JSX.Element {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormSigin>();
  const [loading, setLoading] = useState<boolean>(false);
  const [accessCookie, setAccessCookie, removeAccessCookie] = useCookies([
    "accessToken",
  ]);
  const router = useRouter();

  const [signinMutation] = useMutation(SIGNIN_MUTATION);
  function submitHandler(data: IFormSigin) {
    setLoading(true);
    signinMutation({ variables: data })
      .then((data) => {
        removeAccessCookie("accessToken");
        let accessToken = data.data.login.accessToken;
        setAccessCookie("accessToken", accessToken, {
          path: "/",
          maxAge: 604800, // Expires after 1 week
          sameSite: "lax",
        });

        if (process.browser) {
          localStorage.clear();

          if (data.data.login.user.company.logo_url) {
            localStorage.setItem(
              "logo_url",
              data.data.login.user.company.logo_url
            );
          }

          if (data.data.login.user.company.main_color) {
            localStorage.setItem(
              "main_color",
              data.data.login.user.company.main_color
            );
          }

          if (data.data.login.user.company.name) {
            localStorage.setItem(
              "company_name",
              data.data.login.user.company.name
            );
          }
        }
        const auth = userAuth();
        // @ts-ignore
        //window.location = `/admin/company/${auth.user.company_id}`;
        window.location = `/admin/dashboard`;
      })
      .catch((err: ApolloError) => {
        console.error(err);
        if (err.message === "Invalid credentials") {
          reset({ password: "" });
          toast.error("Identifiant ou mot de passe incorrect !");
        } else {
          toast.error("Erreur lors de la tentative de connexion.");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <>
      {loading && <Loading />}
      <form onSubmit={handleSubmit(submitHandler)} className="grid grid-cols-1">
        <InputField
          type="email"
          label={"Email"}
          errors={errors}
          register={register("email", { required: "Ce champ est requis !" })}
        />
        <InputField
          type="password"
          label={"Mot de passe"}
          errors={errors}
          register={register("password", {
            required: "Ce champ est requis !",
          })}
        />
        <div className="flex justify-end my-2 text-xs">
          <Link
            to={"/auth/forget-password"}
            className="text-secondary-600 hover:text-secondary-700"
          >
            Mot de passe oublié ?
          </Link>
        </div>
        <SubmitButton value="Se connecter" />
      </form>
    </>
  );
}