import React from "react";
import FormSignin from "@/components/Form/Signin";
import { Logo, Text } from "@scanow/ui";
import { APP_MODULE } from "config";
import { APP_NAME, APP_VERSION } from "@scanow/config";

interface ISignin {
  title?: string;
  className?: string;
}

export default function SigninComponent({ title, className = "" }: ISignin) {
  return (
    <section>
      <div className="max-w-md mx-auto space-y-6">
        <div className="grid gap-3">
          <div className="mx-auto">
            <Logo />
          </div>
        </div>
        <div className="mt-4 flex items-center justify-between">
          <span className="border-b border-neutral-900 dark:border-neutral-700 w-1/5 lg:w-1/4"></span>
          <Text.Small appendClassName="uppercase">
            {APP_NAME + " " + APP_MODULE} {APP_VERSION}
          </Text.Small>
          <span className="border-b border-neutral-900 dark:border-neutral-700 w-1/5 lg:w-1/4"></span>
        </div>
        <FormSignin />
      </div>
    </section>
  );
}
