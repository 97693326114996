import React, { useEffect, useState } from "react";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import userAuth from "@/helpers/auth";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import Layout from "@/components/Layout";
import { Container, Text } from "@scanow/ui";
import SigninComponent from "@/components/Auth/Signin";
import Seo from "@/components/Shared/Seo";
import {APP_OG_IMAGE_URL} from "config";
//import { useTranslation } from "next-i18next";
//import { serverSideTranslations } from "next-i18next/serverSideTranslations";

const HomePage: NextPage = () => {
  const [cookie, setCookie, removeCookie] = useCookies(["accessToken"]);
  const router = useRouter();
  const auth = userAuth();
  const pageTitle = "Se connecter";
  const pageDescription =
    "Déclarez un incident en quelques secondes seulement avec Scanow Helpdesk";
  const pageImage = "/images/logo.png" || APP_OG_IMAGE_URL;

  useEffect(() => {
    if (auth.isLogged) {
      if (auth.scanow_role_weight <= 3) {
        router.push("/admin/dashboard");
      } else {
        toast.warning(
          "Vous n'êtes pas autorisé à accéder à cette page. Contacter votre administrateur si vous pensez qu'il s'agit d'une erreur."
        );
        removeCookie("accessToken", { path: "/" });
      }
    } 
  }, []);

  return (
    <Layout layout="public">
      <Seo
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        pageImage={pageImage}
      />
      <Container appendClassName="h-screen pt-20">
        <SigninComponent />
      </Container>
    </Layout>
  );
}

/*
export const getStaticProps = async ({ locale }: any) => ({
  props: {
    ...(await serverSideTranslations(locale, [
      "common",
      "navigation",
      "home",
      "footer",
    ])),
  },
});*/

export default HomePage;
